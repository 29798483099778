const Highcharts = require('highcharts')

const initCharts = () => {
  if ($(".chart-container").length == 0) {
    return
  }

  const chartContainer = $('#chart-container')
  const series = []
  let i = 1
  while (chartContainer.data(`series-${i}`)) {
    series.push({
      name: chartContainer.data(`series-${i}-name`),
      data: chartContainer.data(`series-${i}`),
      visible: i === 1
    })
    i++
  }
  Highcharts.chart('chart-container', {
    chart: {
      type: 'line'
    },
    exporting: {
      enabled: false
    },
    title: {
      text: chartContainer.data('chart-header')
    },
    xAxis: {
      categories: chartContainer.data('x-axis-labels')
    },
    yAxis: {
      title: {
        text: chartContainer.data('y-axis-header')
      }
    },
    series: series
  });
}

export default initCharts
