import initDatepickers from 'src/datepicker'
import initCharts from 'src/charts'
import { delay } from 'src/helpers'

const initIframes = () => {
  const offsetTopAndBottomContainerPadding = 100
  const iFrameHeight = document.documentElement.clientHeight -
    $(".navbar")[0].offsetHeight -
    offsetTopAndBottomContainerPadding
  $(".embedded-spreadsheet").css({
    height: iFrameHeight + "px"
  })
}

const initPlanner = () => {
  $('.js-assumptions-header').on('click', () => {
    const chevron = $('.js-assumptions-header .js-assumptions-chevron')
    if (chevron.hasClass("fa-chevron-down")) {
      $('.js-assumptions').addClass('d-none')
      chevron.addClass("fa-chevron-up").removeClass("fa-chevron-down")
    } else {
      $('.js-assumptions').removeClass('d-none')
      chevron.addClass("fa-chevron-down").removeClass("fa-chevron-up")
    }
  })
}

const initSearch = () => {
  if (new URLSearchParams(window.location.search).has('search')) {
    $('.js-search').focus()
  }
  const initialSearchVal = $('.js-search').val()
  $('.js-search').on('keyup', delay((e) => {
    if ($('.js-search').val() != initialSearchVal) {
      $(e.target).parents('form:first').submit()
    }
  }, 350))
}

const initLoader = () => {
  $('.js-form-with-loader').on('submit', () => {
    $('.form-loader').show()
  })
}

const initAdmin = () => {
  $('.js-show-all-cache').on('click', () => {
    if ($('.js-all-cache').is(":visible")) {
      $('.js-all-cache').hide()
      $('.js-show-all-cache').text("Show all keys")
    } else {
      $('.js-all-cache').show()
      $('.js-show-all-cache').text("Hide all keys")
    }
  })
}

const initCustomBootstrapFileUpload = () => {
  $(".custom-file-input").on("change", function() {
    var fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
  });
}

$(document).on('turbolinks:load', () => {
  initDatepickers()

  $('[data-toggle="tooltip"]').tooltip()
  $('.js-people-popover').each(function() {
    $(this).popover({
      trigger: 'hover',
      content: $(this).find('.popover-content'),
      container: $(this)
    })
  })

  initSearch()
  initCharts()
  initIframes()
  initPlanner()
  initLoader()
  initAdmin()
  initCustomBootstrapFileUpload()
})
