const moment = require('moment')

const setWeek = () => {
  const value = $(".datepicker-week").val()
  const firstDate = moment(value, "MM/DD/YYYY").day(0).format("MM/DD/YYYY")
  const lastDate = moment(value, "MM/DD/YYYY").day(6).format("MM/DD/YYYY")
  if (firstDate) {
    $('.datepicker-week').datepicker('update', firstDate)
    $('.datepicker-week').val(firstDate + " - " + lastDate)
  }
}

const initDatepickers = () => {
  $('.datepicker-day').datepicker({
    orientation: "bottom"
  })

  $(".datepicker-month").datepicker({
    format: "mm-yyyy",
    startView: "months",
    minViewMode: "months"
  })

  setWeek()

  $(".datepicker-week").datepicker({
    autoclose: true,
    format: 'mm/dd/yyyy',
    forceParse: false
  }).on('changeDate', (e) => {
    setWeek()
  }).on('hide', () => {
    setWeek()
    $('.datepicker').removeClass('week-datepicker-dropdown')
  }).on('show', () => {
    $('.datepicker').addClass('week-datepicker-dropdown')
  })

  $('.js-datepicker-switch-item').on('click', function () {
    const currentDatepicker = $('.js-datepicker:visible')
    const nextDatepicker = $(`.${$(this).data("datepicker-class")}`)
    currentDatepicker.hide().find('input').attr('disabled', 'disabled')
    nextDatepicker.show().find('input').removeAttr('disabled')

    $('.js-datepicker-switch-item.active').removeClass('active')
    $(this).addClass('active')
  })
}

export default initDatepickers
